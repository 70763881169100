
.container {
  /* position: relative; */
}
.point-container {
  width: 100vw;
  height: 30vh;
  padding: 10;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.point-container .info-block {
  flex: 2;
  padding: 20px;
  
}

.point-container .info-block .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  color: black;
  padding-bottom: 5px;
}

.point-container .info-block .address {
  color: black;
  padding-bottom: 5px;
}

.point-container .info-block .work {
  color: black;
  padding-bottom: 5px;
}

.point-container .btn-block {
  display: flex;
  flex-direction: row;
  flex: 3;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.point-container .btn-block #details {
  margin-right: 10px;
  text-align: center;
}

.point-container .btn-block #select {
  margin-left: 10px;
  text-align: center;
  background-color: #353868;
  color: white;
}

button  {
  justify-content: center;
  align-items: center;
  flex: 1;
  outline: none;
  border-radius: 4px;
  border: 3px solid #353868;
  color: #353868;
  font-size: 18px;
  background-color: white;
  font-weight: bold
}